/* Core */
body,
html {
  color: #333538;
  font-family: 'Lato', sans-serif;
  line-height: 1.6;
  padding: 0;
  margin: 0;
  font-weight: 400;
  overflow-y:scroll;
  overflow-x:hidden;
}

a {
  color:#333538;
  text-decoration: none;
}

a:hover {
  color: #333538;
  text-decoration: underline;
}

hr {
  border: none;
  height: 1px;
  background-color: #aaa;
  margin: 16px 0;
}

/* Layout */
.content-wrapper {
  padding: 60px 10px;
}

.content {
  max-width: 1024px;
  margin: auto;
}

.focus {
  max-width: 1024px;
  margin: auto;
}

.focus-left {
  float: left;
  width: 0px;
  /*padding: 10px;*/
}

.focus-right {
  float: left;
  width: 125px;
  padding: 10px;
  /*margin-bottom: 600px;*/
}

.focus-middle {
  float: left;
  width: 700px;
  padding: 10px;
}

.focus-menu {
  padding-top: 40px;
  width: 125px;
}

.focus-menu li {
  border-bottom: 1px solid #333538;
  cursor: pointer;
  padding: 5px;
}

.focus-menu li:hover {
  background-color: #eaeaea;
  color:#000;
}

/* Stats */
.stat {
  float: left;
  width: 50%;
  padding-bottom: 10px;
  cursor:pointer;
}

.stat-head {
  background-color: #dadada;
  color: #333538;
  height: 50px;
  border: 1px solid #ccc;
  position:fixed;
  width: 100%;
}

/*.stat-head-title {
  font-size: 25px;
  font-weight: bold;
  float: left;
  padding-top: 5px;
}*/

.stat-head-menu {
  font-size: 20px;
  font-weight: bold;
  float: right;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}

.stat-head-menu a:link {
  color: #333538;
}

/* visited link */
.stat-head-menu a:visited {
  color: #333538;
}

/* mouse over link */
.stat-head-menu a:hover {
  color: #333538;
}

/* selected link */
.stat-head-menu a:active {
  color: #333538;
}

.stat-head-pic {
  width: 55px;
  height: 50px;
  float: left;
  padding-right: 5px;
}

.head-pic-border {
  position:absolute;
}

.head-stencil {
  width:130px;
}

.stat-head-linkedin {
  width: 30px;
  height: 30px;
  float: left;
}

.stat-head-sub {
  font-size: 15px;
  font-weight: bold;
  float: right;
}

.stencil-light-off {
  background-color:#222;
  width: 130px;
  height: 30px;
  float: left;
  margin-top:9px;
}

.stencil-light-on {
  background-color:#0000ff;
  width: 130px;
  height: 30px;
  float: left;
  margin-top:9px;
}

/* Mobile CSS */
@media (max-width: 900px) {
  .stat {
    float: left;
    width: 100%;
    padding-bottom: 10px;
  }
  
  .focus-middle {
    max-width: 98%;
  }
  
  .focus-menu {
    font-size:10px;
    padding: 0px;
    text-align: center;
    margin: auto;
  }

  .focus-right {
    width: 98%;
  }
}

.header {
  text-align: center;
}

/* Chart CSS */
.btn {
  background-color: #aaa;
  border-radius: 4px;
  color: white;
  padding: 0.25rem 0.75rem;
}

.btn:hover {
  background-color: #888;
  color: white;
  text-decoration: none;
}

.btn-npm {
  background-color: #cc3534;
}
.btn-npm:hover {
  background-color: #b8302f;
}
.btn-gh {
  background-color: #444;
}
.btn-gh:hover {
  background-color: #333;
}
.btn-chartjs {
  background-color: #f27173;
}
.btn-chartjs:hover {
  background-color: #e25f5f;
}

.title {
  font-size: 1.2rem;
  font-weight: 700;
  white-space: nowrap;
}

.subtitle {
  font-size: 1.2rem;
}

.links {
  display: flex;
  justify-content: center;
  padding: 8px 0;
}

.links a {
  align-items: center;
  display: flex;
  font-size: 0.9rem;
  margin: 0.2rem;
}

.categories {
  display: flex;
  flex-wrap: wrap;
}

.category {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1 25%;
}

@media (max-width: 640px) {
  .category {
    flex: 1 1 33%;
    margin-bottom: 32px;
  }

  .category:last-of-type {
    margin-bottom: 0;
  }
}

@media (max-width: 512px) {
  .category {
    flex: 1 1 50%;
    margin-bottom: 32px;
  }

  .category:last-of-type {
    margin-bottom: 0;
  }
}

@media (max-width: 420px) {
  .category {
    flex: 1 1 100%;
    margin-bottom: 32px;
  }

  .category:last-of-type {
    margin-bottom: 0;
  }

  html {
    font-size: 14px;
  }
}

.category > .title {
  color: #777;
  font-weight: 400;
  font-size: 1.5rem;
}

.category > .items {
  padding: 8px 0;
}

.entry {
  padding: 0 0 4px 0;
}

.footer {
  text-align: center;
  color: #777;
}

.text-center {
  text-align: center;
}